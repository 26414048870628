@forward './fonts';
@use './fonts' as *;

@font-face {
  font-family: 'Alilato-Medium';
  src: url('../public/fonts/Alilato/Alilato-Medium.ttf');
  font-display: 900;
}

body {
  margin: 0;
  background-color: black !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
/* 
    font-family: Alilato-Medium, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important; */

color: 'white';
    font-family: Alilato-Medium !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 0 !important; */
}
p, h1, h2{
  font-family: Alilato-Medium !important;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;*/
} 


