

body {
  --black: #111111;

  --white: #fdfdfd;

  --green: #22cc88;

  --blue: #0055ff;

  --purple: #8855ff;

  --red: #ff0055;

  --orange: #ee4444;

  --yellow: #ffcc00;

  --mustard: #ffaa00;

  --background: var(--white);

  --accent: var(--black);

  margin: 0;

  padding: 0;

  background-color: var(--background);

  color: var(--accent);

}

* {

  font-weight: 400;

  font-style: normal;

  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {

  font-weight: 600;

  font-style: normal;
}

h1 {
  font-size: 36px;

  font-weight: 700;

  letter-spacing: -1px;

  line-height: 1.2;

  text-align: center;

  margin: 100px 0 40px;
}

h2 {
  font-weight: 400;

  margin: 50px 0 10px;
}

p {
  margin: 0 0 30px 0;

  font-size: 18px;
}

footer {
  position: fixed;

  bottom: 0;

  left: 0;

  right: 0;

  display: flex;

  justify-content: flex-start;

  align-items: center;

  padding: 20px;

  background-image: radial-gradient(
    rgba(0, 0, 0, 0) 1px,

    var(--background) 1px
  );

  background-size: 4px 4px;

  backdrop-filter: blur(3px);

  font-size: 14px;

  line-height: 14px;
}

footer::before {
  display: block;

  content: "";

  position: absolute;

  top: -1px;

  left: 0;

  right: 0;

  height: 1px;

  background: var(--accent);

  opacity: 0.2;
}

footer svg {
  margin-right: 20px;
}

footer a {
  text-decoration: none;

  color: var(--accent);
}

code {

  font-weight: 400;

  font-style: normal;
}

/* article {
  max-width: 500px;

  padding: 20px;

  margin: 0 auto;
} */

h1 {
  text-transform: uppercase;
  font-weight: 900;
  margin: 0 0 1rem;
  padding: 0;
  line-height: 1;
  font-family: Alilato-medium;
  font-size: 5vw;
  font-weight:700;
  @media (max-width: 400px) {
    font-size: 22px;
  }
  @media (min-width: 1200px) {
    font-size: 75px;
  }
}

/* Background */
.bgImage {
  background-image: url("../public/BG1.jpg");
  background-repeat: repeat;

  background-size: 37%;

  background-attachment: fixed;
}

.bgOpacity{
 background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6))
}
@media screen and (min-width: 900px) {
  .bgImage {
    max-width: md;
  }
}
 /* AppBar */
 .logo {
  margin-top: 10px;
}

.MuiAppBar-root {
  flex-direction: row !important;
}

.MuiDrawer-root span {
  font-family: Alilato-Medium !important;
}

.MuiDrawer-root ul{
  padding-top: 100px;
}

/* Carouse 1 */
.sliderImages {
  width: 100%;
  border-radius: 30px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  opacity: 1;
}

.carouselContainer .react-multiple-carousel__arrow {
  border: 2px solid #fcc015 !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--right::before {
  color: #fcc015 !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--right::after {
  color: #fcc015 !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--left::before {
  color: #fcc015 !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--left::after {
  color: #fcc015 !important;
}

.carouselContainer .react-multiple-carousel__arrow {
  min-width: 30px !important;

  min-height: 30px !important;
}

/* .carouselContainer .react-multiple-carousel__arrow {
  border: 2px solid yellow !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--right::before {
  color: yellow !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--right::after {
  color: yellow !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--left::before {
  color: yellow !important;
}

.carouselContainer .rtl.react-multiple-carousel__arrow--left::after {
  color: yellow !important;
}

.carouselContainer .react-multiple-carousel__arrow {
  min-width: 30px !important;

  min-height: 30px !important;
} */


.carouselContainer {
  background: #090915;
  display: flex;

  flex-direction: column;

  padding-top: 30px;

  padding-bottom: 50px;
}

.carouselHeader {
  font-weight: bold;
  margin: 0px;
  color: white;
  margin-bottom: 20px;
  /* margin-left: 89%; */
}

.header-carousel{
  display: flex;
  flex-direction: column;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-5%, -60%);
  -ms-transform: translate(-50%, -60%);
  text-align: center;
}

.imageCard {
  width: 300;

  height: 400px;
}


.text {
  color: black;

  font-size: 16px;

  /* padding: 16px 32px; */
}

.middle .MuiIconButton-root {
  padding: 0 !important;
}

/*Presenter Carousel*/

.presentercarousel .react-multiple-carousel__arrow {
  border: 2px solid gray !important;
}
.presentercarousel .react-multiple-carousel__arrow--right {
  top:  calc(80% + 1px);
  left: calc(0% + 1px) !important;
}
.presentercarousel .react-multiple-carousel__arrow--left {
  top:  calc(80% + 1px);
  left: calc(6em + 1px) !important;
  /* left: calc(2vw + 1px) !important; */
  right: auto !important;


}
.presentercarousel .rtl.react-multiple-carousel__arrow--right::before {
  color: #fcc015 !important;
  font-size: 15px;
  font-weight: 900;

}

.presentercarousel .rtl.react-multiple-carousel__arrow--right::after {
  color: #fcc015 !important;
  font-size: 15px;
  font-weight: 900;
  
}

.presentercarousel .rtl.react-multiple-carousel__arrow--left::before {
  color: #fcc015 !important;
  font-size: 15px;
  font-weight: 900;
}

.presentercarousel .rtl.react-multiple-carousel__arrow--left::after {
  color: #fcc015 !important;
  font-size: 15px;
  font-weight: 900;
}

.presentercarousel .rtl.react-multiple-carousel__arrow:disabled{
 background-color: #ccc !important;
 border: 1px solid black !important;
 color: lightyellow !important;
}

.presentercarousel .react-multiple-carousel__arrow {
  min-width: 70px !important;

  min-height: 70px !important;
}

.spanTextPresenter{
  font-size: 50px;
  direction: rtl;
  font-weight: 900;
  margin-left: 10px;
  color: #fcc015;
  margin-bottom: 18px;
}


.presenterSection{
  background-color: black;
  display: flex;
  flex-direction: row;
  margin-left: 0px !important;
  width: auto  !important;
  box-shadow: 0px 0 30px 20px rgba(0, 0, 0, 0.5);
}

.presenterCarouselSection{
  display: flex;
  flex-direction: column;
  padding-bottom: 75px;
}

.imageCardPresenter {
  width: 100%;
  height: 250px;
}

.headerCarouselName{
  color: white; font-size:2.2em;
  direction: rtl;
}
.outsideTextPresenter{
  position: absolute;
  top: 80%;
  /* left: 40%; */
}
.presentercarousel ul{
 padding-top: 50px; 
 padding-bottom: 50px;
 transition: transform 1000ms ease-in-out 0ms !important;
}


/* Follow Us Section */

.followUsContainer {
  justify-content: space-evenly;
}

.followUsContainer img {
  width: 50%;
}

.followUsContainer h2 {
  display: flex;
 font-size: 40px;
  align-items: center;
}



/* Video Section */

.outter{
  width:100%;
  /* height:100%; */
  display:flex;
  flex-direction:column;
  justify-content:center;
  position: relative;
  overflow: hidden;
  
}

.video-container {
  width:100%;
  position: relative;
  overflow: hidden;
}

video {
  object-fit: cover; 
  /* position: absolute; */
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.video-container:after {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(black, .2);
  z-index: 1;
}


.video-container:before {
  content:"";
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  z-index:1;
  opacity: 90%;
  background:linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.callout {
  display: flex;
  flex-direction: column-reverse;
  justify-content:center;
  /* align-items: center; */
  height: 100%;
  /* text-align: center; */
  position: absolute;
  top: 0; 
  left: 60%;
  z-index: 10;
  /* width: 50%;  */
  margin:auto;
}

.option2{
  margin-top:50px;
  .callout{
    /* text-align:center; */
    align-self:flex-start;
    
    h1{
      text-align:left;
      width:100%;
    }

  }
}



.presenterSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent; /* Border to blur */
  filter: blur(10px); /* Adjust the blur radius as needed */
  pointer-events: none; /* Allows interactions with underlying elements */
  z-index: -1; /* Place it behind the content */
}
.presenterSection{
  position: relative; /* Needed for pseudo-element */
  overflow: hidden;
}
.spanText{
  direction: rtl;
  font-size: 50px;
  font-weight: 900;
  margin-left: 10px;
  color: #fcc015;
  margin-bottom: 18px;
}
.bgCard{
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
}
@media screen and (min-width: 768px){
  .callout{
      float: right;
  }

  .containerCard:hover .mainCard {
    opacity: 0.5;
  
    cursor: pointer;
  
   /* transition: opacity 400ms; */
  
    /* margin: 0 !important; */
  
    /* border: 1px solid #fcc015; */
  
    border-radius: 10px;
  }
  
  .containerCard {
  
    transform: scale(1);
    transition: transform 0.6s ease; 
  }
  
  .containerCard:hover {
    /* border: 1px solid #fcc015; */
    transform: scale(1.03);
    border-radius: 20px;
  }
 
  .containerCard:hover .imageCard{
    border: 1px solid #fcc015;
  }
  /* .carouselContainer .react-multi-carousel-item  {
    transform: scale(1);
    transition: transform 0.6s ease;
    height: 100% !important;
  }

    .carouselContainer .react-multi-carousel-item:hover {
   border: 1px solid #fcc015;
    transform: scale(1.01);
    
    border-radius: 10px
  } */

  .containerCard:hover .outsideText {
    display: none;
  }
  
  .containerCard:hover .middle {
    opacity: 1;
  
    z-index: 99;
  
    cursor: pointer;
  }
  
  /* .presentercarousel .carousel{
    position: inherit;
  } */
}

@media screen and (max-width: 767px){
.followUsContainer{
  flex-direction: column-reverse !important;
  align-items: center !important;
}


  .presenterSection{
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }
  .presenterDiv{
    align-items: center !important;
  }
.presenterDiv h2{
  margin: 0px !important
}
  .spanText{
      font-size: 1.5em;
      margin-left: 0;
  }
  .bgImage{
    background-size: 100%;
    /* background-attachment: inherit; */
  }
  .header-carousel{
    text-align: center !important;
  }
  .header-carousel h2{
    font-size: 25px !important;
  }
  .header-carousel b{
    font-size: 25px !important;
  }
  hr{
    display: none !important;
  }
  .presentercarousel ul{
    padding: 0;
  }
  /* .presentercarousel .react-multi-carousel-list{
height: 60vw;
  } */
  .mainCardPresenter p{
    font-size: .75rem;
  }
  .imageCardPresenter{
    height: 15em;
  }
  /* .outsideTextPresenter{
    top: 70%;
    left: 30%;
  } */
  .presentercarousel .react-multiple-carousel__arrow--left {
   top: auto !important;
    left: auto !important;
    right: auto !important;
  }

  .presentercarousel .react-multiple-carousel__arrow--right{
    right: auto !important;
    left: 0 !important;
    top:  auto !important;
  }

  .space{
    margin-top: 15%  !important;
  }
  .gridItemPresenter img{
    width: 40% !important;
  }
  .gridItemPresenter h2{
    font-size: 25px !important;
  }
  .presentercarousel .rtl.react-multiple-carousel__arrow--right::before {

    font-size: 10px;
  
  }
  
  .presentercarousel .rtl.react-multiple-carousel__arrow--right::after {
  
    font-size: 10px;
    
  }
  
  .presentercarousel .rtl.react-multiple-carousel__arrow--left::before {
  
    font-size: 10px;
  }
  
  .presentercarousel .rtl.react-multiple-carousel__arrow--left::after {
  
    font-size: 10px;
  }
  .presenterCarouselSection{
    padding-bottom: 10px;
  }
  .callout h1{
    font-size:  1em !important;
  }
}

@media screen and (max-width: 767px) {

  .carouselContainer .react-multiple-carousel__arrow {
    min-width: 40px !important;
    min-height: 40px !important;
  }

  .logo {
    width: 50%;
    height: auto;
  }

  .logoContainer {
    margin-right: -5em;
  }


  .carouselHeader {
    font-size: 25px;
    margin-left: 0%;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }

  .imageCard {
    height: 250px;
  }

  .followUsContainer img {
    height: 50%;
  }

  .followUsContainer h2 {
    font-size: 12px;
    margin: 0 !important;
  }

  .text {
    font-size: 12px;
    padding: 0px !important;
  }

  .mobile {
    display: none !important;
  }
  .presentercarousel .react-multiple-carousel__arrow{
    min-width: 30px !important;
    min-height: 30px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .presentercarousel .react-multiple-carousel__arrow--right {
    left: calc(5% + 1px) !important;
  }
  .imageCard{
    height: 300px;
  }
  .logo {
    /* width: 80%; */

    height: auto;
  }

  .carouselHeader {
    font-weight: bold;
    /* margin-left: 79%; */
    direction: rtl;
    padding-right: 1em;
  }
  .followUsContainer h2 {

    font-size: 30px;
  }
  .presentercarousel .react-multiple-carousel__arrow--left{
    top: calc(80% + 1px)
  }
   .presentercarousel .react-multiple-carousel__arrow--right{
    top: calc(80% + 1px)
  }

 .headerCarouselName{
    font-size: 25px;
 }
 .spanTextPresenter{
  font-size: 25px;
 }
 .presentercarousel .rtl.react-multiple-carousel__arrow--left{
 margin-left:0em;
}
.imageCardPresenter{
  height: 150px;
}
.mainCardPresenter p {
  font-size: 0.75rem;
}
hr{
  max-width: 30vw !important;
  left: 3.5em !important;
}
/* .gridItemPresenter{
  display: flex !important;
  align-items: center !important;
} */
.presenterDiv img{
  width: 20em !important;
    }

.outsideTextPresenter{
 
  left: 30%;
}
 
.presentercarousel .react-multiple-carousel__arrow {
  min-width: 50px !important;

  min-height: 50px !important;
}
}


@media screen  and (min-width: 1500px) {
  .headerCarouselName{
    font-size: 3em;
 }
}

@media screen and (min-width: 768px) and  (max-width: 900px){
 .presenterSection .MuiGrid-root{
  max-width: 50%;
}
}

@media  screen and (min-width: 1201px ) {
  .outsideTextPresenter{
   
    right: 1em;
  }
 .presentercarousel .carousel{
   height: 400px
  }
  .presentercarousel ul{
    padding-top: 0 !important;
  }
  hr{
   max-width: 70% !important;
    left: 12% !important;
    margin-top: -40px !important;
  }
  .presenterDiv img{
width: 27.5em !important;
  }
  /*  .presentercarousel .react-multiple-carousel__arrow--right{
    top: calc(85% + 1px);
  } */
}

.reserved-space {
  visibility: hidden;
  position: absolute;
  width: 100%; /* Adjust the width as needed */
  height: 100%; /* Adjust the height as needed */
  pointer-events: none;
  opacity: 0;
  /* You can also add other styles or transitions as needed */
}